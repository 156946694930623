import { Fragment, useState } from "react"
import { Dialog, Tab, Transition } from '@headlessui/react'
import { XIcon } from '@heroicons/react/outline'

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ')
}

const navigation = {
  categories: [
    {
      id      : 'bikes',
      name    : 'Bikes',
      featured: [
        {
          name    : 'Capra Pro Race 29',
          href    : '/',
          imageSrc: 'https://cdn-prod.yt-industries.com/media/image/6f/94/89/01_CAPRA29_CF_Pro_Race_Titan_Silver_Seite_1280x1280.png',
          imageAlt: 'Bike'
        }
      ]
    }
  ]
}
const MobileMenu = () => {
  const [open, setOpen] = useState(false)

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-40 lg:hidden" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="transition-opacity ease-linear duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition-opacity ease-linear duration-300"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25"/>
        </Transition.Child>

        <div className="fixed inset-0 flex z-40">
          <Transition.Child
            as={Fragment}
            enter="transition ease-in-out duration-300 transform"
            enterFrom="-translate-x-full"
            enterTo="translate-x-0"
            leave="transition ease-in-out duration-300 transform"
            leaveFrom="translate-x-0"
            leaveTo="-translate-x-full"
          >
            <Dialog.Panel
              className="relative max-w-xs w-full bg-white shadow-xl pb-12 flex flex-col overflow-y-auto">
              <div className="px-4 pt-5 pb-2 flex">
                <button
                  type="button"
                  className="-m-2 p-2 rounded-md inline-flex items-center justify-center text-gray-400"
                  onClick={() => setOpen(false)}
                >
                  <span className="sr-only">Close menu</span>
                  <XIcon className="h-6 w-6" aria-hidden="true"/>
                </button>
              </div>

              Links
              <Tab.Group as="div" className="mt-2">
                <div className="border-b border-gray-200">
                  <Tab.List className="-mb-px flex px-4 space-x-8">
                    {navigation.categories?.map((category) => (
                      <Tab
                        key={category.name}
                        className={({selected}) =>
                          classNames(
                            selected ? 'text-indigo-600 border-indigo-600' : 'text-gray-900 border-transparent',
                            'flex-1 whitespace-nowrap py-4 px-1 border-b-2 text-base font-medium'
                          )
                        }
                      >
                        {category.name}
                      </Tab>
                    ))}
                  </Tab.List>
                </div>
                <Tab.Panels as={Fragment}>
                  {navigation.categories?.map((category) => (
                    <Tab.Panel key={category.name} className="pt-10 pb-8 px-4 space-y-10">
                      <div className="space-y-4">
                        {category.featured?.map((item, itemIdx) => (
                          <div
                            key={itemIdx}
                            className="group relative aspect-w-1 aspect-h-1 rounded-md bg-gray-100 overflow-hidden"
                          >
                            <img
                              src={item.imageSrc}
                              alt={item.imageAlt}
                              className="object-center object-cover group-hover:opacity-75"
                            />
                            <div className="flex flex-col justify-end">
                              <div
                                className="p-4 bg-white bg-opacity-60 text-base sm:text-sm">
                                <a href={item.href}
                                   className="font-medium text-gray-900">
                                                                        <span className="absolute inset-0"
                                                                              aria-hidden="true"/>
                                  {item.name}
                                </a>
                                <p aria-hidden="true"
                                   className="mt-0.5 text-gray-700 sm:mt-1">
                                  Shop now
                                </p>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </Tab.Panel>
                  ))}
                </Tab.Panels>
              </Tab.Group>

              <div className="border-t border-gray-200 py-6 px-4">
                <a href="/" className="-m-2 p-2 flex items-center">
                  <img
                    src="https://catamphetamine.gitlab.io/country-flag-icons/3x2/EU.svg"
                    alt="EU"
                  />
                  <span className="ml-3 block text-base font-medium text-gray-900">EUR</span>
                  <span className="sr-only">change currency</span>
                </a>
              </div>
            </Dialog.Panel>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>

  )
}

export default MobileMenu
