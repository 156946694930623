import { observer } from 'mobx-react'
import {
  FC, lazy, Suspense
} from 'react'
import LoadingSplash from "../components/LoadingSplash"


const LandingPage = lazy(() => import('../components/LandingPage'))

const BikesListWrapper: FC = () => {

  return (
    <Suspense fallback={<LoadingSplash/>}>
      <LandingPage/>
    </Suspense>
  )
}
export default observer(BikesListWrapper)
