import { FC } from 'react'

const LoadingSplash: FC = () => (
  <div className="bg-white min-h-full px-4 py-16 sm:px-6 sm:py-24 md:grid md:place-items-center lg:px-8">
    <div className="max-w-max mx-auto">
      <main className="sm:flex">
        <p className="text-4xl font-extrabold text-indigo-600 sm:text-5xl">Loading</p>
      </main>
    </div>
  </div>
)
export default LoadingSplash
