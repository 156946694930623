import * as contentful from "contentful"
import { Bike } from "../types/bike"
import { Color } from "../types/color"
import { Plan } from "../types/plan"
import { Additional } from "../types/additional"


const client = contentful.createClient({
  space      : process.env.REACT_APP_CONTENTFUL_SPACE_ID || '',
  accessToken: process.env.REACT_APP_CONTENTFUL_ACCESS_TOKEN || ''
})


export function getBikes() {
  return client.getEntries<Bike>({
    content_type: 'bikes'
  })
}

export function getBike(entryId: string) {
  return client.getEntries<Bike>({content_type: 'bikes', 'sys.id': entryId, resolveLinks: true, include: 10})
}

export function getInsurancesPlansForBike(bikeEntryId: string) {
  return client.getEntries<Plan>()
}

export function getPlans() {
  return client.getEntries<Plan>({
    content_type: 'plans'
  })
}

export function getAdditional() {
  return client.getEntries<Additional>({
    content_type: 'additionalItem'
  })
}

export function getPlansByIds(ids: string[]) {
  return client.getEntries<Plan>({
    content_type: 'plans',
    "sys.id[in]": ids.toString(),
  })
}

export function getColoursByIds(ids: string[]) {
  return client.getEntries<Color>({
    content_type: 'colours',
    "sys.id[in]": ids.toString(),
  })
}


