import {FC, useState, useEffect} from 'react'
import SyntaxHighlighter from "react-syntax-highlighter";
import {atomOneLight, dracula, lightfair} from 'react-syntax-highlighter/dist/cjs/styles/hljs';
import {requests} from "../api/requests";
import {responses} from "../api/responses";

type Props = {
    path: string;
};

const API: FC<Props> = ({path}) => {
    const [requestBody, setRequest] = useState([""]);
    const [responseBody, setResponse] = useState([""]);

    useEffect(() => {
        switch(path) {
        case
            "products"
        :
            {
                const method = "POST https://r81dxbskx0.execute-api.eu-west-1.amazonaws.com/staging/order\n";
                const body = "Body:";
                setRequest([method, body, JSON.stringify(requests[0], null, 2)]);
                setResponse([JSON.stringify(responses[0], null, 2)]);

                break;
            }
        case
            "cart"
        :
            {
                let req = [];
                let res = [];
                const parameter = "Parameter:";
                const body = "Body:";


                const method1 = "GET https://r81dxbskx0.execute-api.eu-west-1.amazonaws.com/staging/order/{identifier}/cost";
                const req1 = JSON.stringify(requests[1], null, 2);
                const method2 = "POST https://r81dxbskx0.execute-api.eu-west-1.amazonaws.com/staging/order/{identifier}/verification";
                const req2 = JSON.stringify(requests[2], null, 2);
                const txt1 = "Request #1";
                const txt2 = "Request #2";

                req.push(txt1);
                req.push(method1);
                req.push(parameter);
                req.push(req1);

                req.push(txt2);
                req.push(method2);
                req.push(body);
                req.push(req2);

                setRequest(req);

                const res1 = JSON.stringify(responses[1], null, 2);
                const res2 = JSON.stringify(responses[2], null, 2);
                const res3 = JSON.stringify(responses[3], null, 2);
                const text1 = "Response #1";
                const text2 = "Response #2"
                const text = "or";

                res.push(text1);
                res.push(res1);

                res.push(text2);
                res.push(res2);
                res.push(text);
                res.push(res3);

                setResponse(res);

                break;
            }
        case
            "payment"
        :
            {
                const method = "POST https://r81dxbskx0.execute-api.eu-west-1.amazonaws.com/staging/order/{identifier}/confirmation";
                const param = "Parameter:";
                setRequest([method, param, JSON.stringify(requests[3], null, 2)]);

                const res1 = JSON.stringify(responses[4], null, 2);
                const res2 = JSON.stringify(responses[5], null, 2);
                const text = "or";
                let res = [];

                res.push(res1);
                res.push(text);
                res.push(res2);

                setResponse(res);

                break;
            }
        case
            "order-confirmation"
        :
            {
                const method = "GET https://r81dxbskx0.execute-api.eu-west-1.amazonaws.com/staging/order/{identifier}/confirmation";
                const param = "Parameter:";
                setRequest([method, param, JSON.stringify(requests[4], null, 2)]);

                const res1 = JSON.stringify(responses[6], null, 2);
                const res2 = JSON.stringify(responses[7], null, 2);
                const text = "or";
                let res = [];

                res.push(res1);
                res.push(text);
                res.push(res2);

                setResponse(res);

                break;
            }
        default:
            {
                setRequest([JSON.stringify(requests[0], null, 2)]);
                setResponse([JSON.stringify(responses[0], null, 2)]);

                break;
            }
        }
    });

    return (
        <div className="flex flex-col justify-around max-w-7xl mx-auto sm:px-6 lg:px-8">
            <div className="bg-[#282A36] my-4 py-8 px-8 text-sm text-white rounded">
                <div> Request: </div>
                { requestBody.map(req =>
                    <SyntaxHighlighter children={req} language="javascript" style={dracula}/>)
                }
            </div>
            <div className="bg-zinc-50 my-4 py-8 px-8 text-sm text-black rounded">
                <div> Response: </div>
                { responseBody.map(res =>
                    <SyntaxHighlighter children={res} language="javascript" style={atomOneLight}/>)
                }
            </div>
        </div>
    )
}

export default API