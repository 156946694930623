import { observer } from 'mobx-react'
import {
  FC, lazy, Suspense, useContext, useEffect
} from 'react'
import LoadingSplash from "../components/LoadingSplash"
import { ApplicationContext } from "../index"
import { useParams } from "react-router-dom"


const PaymentPage = lazy(() => import('../components/PaymentPage'))

const PaymentWrapper: FC = () => {
  const appContext = useContext(ApplicationContext)
  const {entryId} = useParams()

  useEffect(() => {
    appContext.fetchBike(entryId || '')
  }, [entryId])

  return (
    <Suspense fallback={<LoadingSplash/>}>
      <PaymentPage/>
    </Suspense>
  )
}
export default observer(PaymentWrapper)
