import { MenuIcon, SearchIcon, ShoppingCartIcon, UserIcon } from "@heroicons/react/outline"
import { Popover, Transition } from "@headlessui/react"
import { observer } from "mobx-react"
import { FC, Fragment, useContext, useState } from "react"
import { Link, useNavigate } from "react-router-dom"
import BikmoGreenBlack from "../icons/BikmoGreenBlack"
import { ApplicationContext } from "../index"


function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ')
}

const Header: FC = () => {
  const [open, setOpen] = useState(false)
  const navigate = useNavigate()
  const appContext = useContext(ApplicationContext)

  const handleSelectBike = (id:string) => {
    setOpen(false)
    navigate(`/products/${id}`)
  }

  const handleCurrencySelection = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const newCurrency = e.target.value.toUpperCase()
    appContext.setCurrency(newCurrency)
  }

  return (
    <header className="relative bg-white">
      <nav aria-label="Top" className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="border-b border-gray-200">
          <div className="h-16 flex items-center justify-between">
            <div className="flex-1 flex items-center lg:hidden">
              <button
                type="button"
                className="-ml-2 bg-white p-2 rounded-md text-gray-400"
                onClick={() => setOpen(true)}
              >
                <span className="sr-only">Open menu</span>
                <MenuIcon className="h-6 w-6" aria-hidden="true"/>
              </button>

              <a href="#" className="ml-2 p-2 text-gray-400 hover:text-gray-500">
                <span className="sr-only">Search</span>
                <SearchIcon className="w-6 h-6" aria-hidden="true"/>
              </a>
            </div>

            {/* Flyout menus */}
            <Popover.Group className="hidden lg:flex-1 lg:block lg:self-stretch">
              <div className="h-full flex space-x-8">
                <Popover className="flex">
                  {({open, close}) => (
                    <>
                      <div className="relative flex w-56 text-left">
                        <Popover.Button
                          className={classNames(
                            open ? 'text-indigo-600' : 'text-gray-700 hover:text-gray-800',
                            'relative z-10 flex text-left items-center justify-center transition-colors ease-out duration-200 text-sm font-medium'
                          )}
                        >
                          Bikes
                          <span
                            className={classNames(
                              open ? 'bg-indigo-600' : '',
                              'absolute bottom-0 inset-x-0 h-0.5 transition-colors ease-out duration-200 sm:mt-5 sm:transform sm:translate-y-px'
                            )}
                            aria-hidden="true"
                          />
                        </Popover.Button>
                      </div>
                      <Transition
                        as={Fragment}
                        enter="transition ease-out duration-200"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="transition ease-in duration-150"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                      >
                        <Popover.Panel className="absolute z-10 top-full inset-x-0">
                          {/* Presentational element used to render the bottom shadow, if we put the shadow on the actual panel it pokes out the top, so we use this shorter element to hide the top of the shadow */}
                          <div className="absolute inset-0 top-1/2 bg-white shadow"
                               aria-hidden="true"/>

                          <div className="relative bg-white">
                            <div className="max-w-7xl mx-auto px-8">
                              <div
                                className="w-full gap-y-10 gap-x-8 py-8">
                                <div
                                  className="flex flex-wrap h-64 w-full text-sm">
                                  {appContext.bikes?.map((item) => (
                                    <div
                                      key={item.name}
                                      className="w-64 h-64 z-30 aspect-w-1 mx-3 my-3 rounded-md outline-solid"
                                    >
                                      <img
                                        src={item.images[0].fields.file.url}
                                        alt={item.images[0].fields.description}
                                        className="object-center object-scale-down group-hover:opacity-75"
                                      />
                                      <div
                                        className="ml-3 p-3 flex mt-4 flex-col justify-end">
                                        <div
                                          className="font-medium text-gray-900">
                                          <button  type="button" className="group -ml-2 p-2 flex items-start"
                                                onClick={() => {close(); handleSelectBike(item?.entry_id || '')}}
                                          >
                                            <span
                                              className="mt-1"
                                              aria-hidden="true"/>
                                            {item.name}
                                          </button>
                                          <p aria-hidden="true"
                                             className="mt-0.5 text-gray-700 sm:mt-1 font-medium font-bold">
                                            Shop now
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  ))}
                                </div>
                              </div>
                            </div>
                          </div>
                        </Popover.Panel>
                      </Transition>
                    </>
                  )}
                </Popover>
              </div>
            </Popover.Group>

            {/* Logo */}
            <div>
              <button type="button" onClick={() => navigate('/')}>
                <BikmoGreenBlack className="h-16 -ml-64" />
              </button>
            </div>

            <div className="flex-1 flex items-center justify-end">
              <a href="#" className="hidden text-gray-700 hover:text-gray-800 lg:flex lg:items-center">
                <label>
                  <select onChange={handleCurrencySelection}>
                    <option value="gbp"> GBP </option>
                    <option value="eur"> EUR </option>
                  </select>
                </label>
                <span className="sr-only">, change currency</span>
              </a>

              {/* Search */}
              <a href="#" className="hidden ml-6 p-2 text-gray-400 hover:text-gray-500 lg:block">
                <span className="sr-only">Search</span>
                <SearchIcon className="w-6 h-6" aria-hidden="true"/>
              </a>

              {/* Account */}
              <a href="#" className="p-2 text-gray-400 hover:text-gray-500 lg:ml-4">
                <span className="sr-only">Account</span>
                <UserIcon className="w-6 h-6" aria-hidden="true"/>
              </a>

              {/* Cart */}
              <div className="ml-4 flow-root lg:ml-6">
                <Link className="group -m-2 p-2 flex items-center"
                      to={`/cart/${appContext.bike?.entry_id || ''}`}
                >
                  <ShoppingCartIcon
                    className="flex-shrink-0 h-6 w-6 text-gray-400 group-hover:text-gray-500"
                    aria-hidden="true"
                  />
                  <span
                    className={classNames(appContext.bikesInCart? 'text-red-500':'text-gray-700',"ml-2 text-sm font-medium group-hover:text-gray-800")}>{appContext.bikesInCart ? 1 : 0}</span>
                  <span className="sr-only">items in cart, view bag</span>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </header>
  )
}

export default observer(Header)
