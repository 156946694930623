import {Outlet, useLocation} from "react-router-dom"
import Footer from "./Footer"
import Header from "./Header"
import MobileMenu from "./MobileMenu"
import {useState} from "react";
import API from "./API";

const MainLayout = () => {
    const [displayPreview, setDisplay] = useState<boolean>(true);
    const location = useLocation();
    const path = location.pathname.split("/")[1];

    const showPreview = () => {
        setDisplay(true);
    }

    const showApi = () => {
        setDisplay(false);
    }
    return (
        <div className="bg-white">
            <div className="bg-zinc-300 flex justify-end items-center max-w-7xl mx-auto sm:px-6 lg:px-8 py-2">
                <div className="flex items-center space-x-1 rounded-lg bg-slate-100">
                    <button className={displayPreview ?
                        "flex items-center rounded-md py-[0.4375rem] pl-2 pr-2 text-sm lg:pr-3 bg-white shadow" :
                        "flex items-center rounded-md py-[0.4375rem] pl-2 pr-2 text-sm lg:pr-3"}
                        onClick={showPreview} >
                        <svg width="24" height="24" fill="none" viewBox="0 0 24 24">
                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M19.25 12C19.25 13 17.5 18.25 12 18.25C6.5 18.25 4.75 13 4.75 12C4.75 11 6.5 5.75 12 5.75C17.5 5.75 19.25 11 19.25 12Z"/>
                            <circle cx="12" cy="12" r="2.25" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
                        </svg>
                        <p> Preview </p>
                    </button>
                    <button className={displayPreview ?
                        "flex items-center rounded-md py-[0.4375rem] pl-2 pr-2 text-sm lg:pr-3" :
                        "flex items-center rounded-md py-[0.4375rem] pl-2 pr-2 text-sm lg:pr-3 bg-white shadow"}
                        onClick={showApi}>
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M15.75 8.75L19.25 12L15.75 15.25" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M8.25 8.75L4.75 12L8.25 15.25" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                        <p> API </p>
                    </button>
                </div>
            </div>
            {displayPreview ?
                <div>
                    {/* Mobile menu */}
                    <MobileMenu/>
                    <Header/>
                    <main className="max-w-7xl mx-auto sm:pt-16 sm:px-6 lg:px-8">
                        <Outlet/>
                    </main>
                    <br/>
                    <Footer/>
                </div>
                :
                <API path={path}/>
            }
        </div>
    )
}
export default MainLayout
