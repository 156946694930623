export const requests = [
    {
        "slug": "uk-bike-free",
        "products": [
            {
                "slug": "uk-free",
                "config": {
                    "free-bike": {
                        "make": "YT",
                        "model": "Capra Pro Race",
                        "value": 2800,
                        "ebike": false,
                        "retailer": "Example Retailer"
                    }
                }
            }
        ]
    },
    {
        "identifier": "5VuajiKY24ALNp7fcWAk12"
    },
    {
        "eligibility-confirmed": true,
        "terms_accepted": true,
        "demand_needs_met": true
    },
    {
        "identifier": "5VuajiKY24ALNp7fcWAk12"
    },
    {
        "identifier": "5VuajiKY24ALNp7fcWAk12"
    }
];