export const responses = [
    {
        "slug": "uk-bike-free",
        "products": [
            {
                "slug": "uk-free",
                "config": {
                    "free-bike": {
                        "make": "YT",
                        "model": "Capra Pro Race",
                        "value": 2800,
                        "ebike": false,
                        "retailer": "Example Retailer"
                    }
                }
            }
        ]
    },
    {
        "cost": 1234,
        "products": {
            "de-ride": 1000,
            "de-pickup": 234
        }
    },
    {
        "response": "200 OK"
    },
    {
        "response": "422 UNPROCESSABLE ENTITY"
    },
    {
        "response": "200 OK"
    },
    {
        "response": "500 SERVER ERROR"
    },
    {
        "response": "200 OK"
    },
    {
        "response": "404 NOT FOUND"
    }
];