import { makeAutoObservable } from 'mobx'
import { BikeWithEntry } from "../types/bike"
import { getAdditional, getBike, getBikes, getColoursByIds, getPlans, getPlansByIds } from "../api"
import { Color } from "../types/color"
import { Plan } from "../types/plan"
import { Additional } from "../types/additional"


class BikeStore {

  bike: BikeWithEntry | null = null

  bikes: BikeWithEntry[] | null = null

  plans: Plan[] | null = null

  bikePlans: Plan[] | null = null

  additionalItems : Additional[] | null = null

  selectedAdditionalItems : Additional[] | null = null

  bikeColours: Color[] | null = null

  bikesInCart: BikeWithEntry[] | null = null

  selectedBikeSize  = 0

  selectedInsurance: Plan | null = null

  selectedBikeLock = false

  currency = 'GBP'

  selectedBottleHolder = false
  totalPrice = (this.bike?.price || 0) + (this.selectedInsurance ? this.selectedInsurance?.price : 0) + (this.selectedBikeLock ? 30 : 0) + (this.selectedBottleHolder ? 10 : 0)

  constructor() {
    makeAutoObservable(this)
  }

  setCurrency(newCurrency:string) {
    this.currency = newCurrency;
    if (this.bike) {
      if (newCurrency !== 'GBP') {
        let newPrice = (this.bike.price || 0) / 0.86;
        this.bike.price = newPrice;
      } else {
        let newPrice = (this.bike.price || 0) * 0.86;
        this.bike.price = newPrice;
      }
    }
  }

  setTotalPrice(){
    let newPrice = (this.bike?.price || 0) + (this.selectedInsurance ? this.selectedInsurance?.price || 0 : 0)
    if(this.selectedAdditionalItems){
    newPrice = newPrice + this.selectedAdditionalItems?.reduce( (sum, curr) => sum + Number(curr.price), 0)
    }
    this.totalPrice = newPrice
  }

  setBike(bike1: BikeWithEntry | null) {
    this.bike = bike1
    this.setTotalPrice()
  }

  setBikes(bike1: BikeWithEntry[]) {
    this.bikes = bike1
  }

  setSelectedInsurance(plan: Plan | null) {
    this.selectedInsurance = plan
    this.setTotalPrice()
  }

  setAdditionalItems(items: Additional[] | null) {
    this.additionalItems = items
    this.setTotalPrice()
  }

  setSelectedAdditionalItems(items: Additional[] | null) {
    this.selectedAdditionalItems = items
    this.setTotalPrice()
  }

  setSelectedBikeSize(size: number){
    this.selectedBikeSize = size
  }

  setBikesInCart(bike1: BikeWithEntry[] | null) {
    this.bikesInCart = bike1
    this.setTotalPrice()
  }

  setSelectedBikeLock(value: boolean) {
    this.selectedBikeLock = value
    this.setTotalPrice()
  }

  setSelectedBottleHolder(value: boolean) {
    this.selectedBottleHolder = value
    this.setTotalPrice()
  }

  setBikePlans(bikePlans: Set<string>) {
    const plans = getPlansByIds(Array.from(bikePlans.values()))
    const planArray: Array<Plan> = []
    plans.then((res) => {
      res.items.map((plans) => {
        planArray.push(plans.fields)
      })
      this.bikePlans = planArray
    })
    console.log("[PLANS]",this.bikePlans)
  }
  setBikeColours(values: Set<string>) {
    const data = getColoursByIds(Array.from(values.values()))
    const dataArray: Array<Color> = []
    data.then((res) => {
      res.items.map((item) => {
        dataArray.push(item.fields)
      })
      this.bikeColours = dataArray
    })
    console.log("[COLOURS]", this.bikeColours)
  }


  setPlans(plan1: Plan[]) {
    this.plans = plan1
  }

  fetchBike(entryId: string) {
    const planIds = new Set<string>([])
    const colourIds = new Set<string>([])
    if (entryId) {
      const bike = getBike(entryId)
      bike.then((res) => {
            const resItem = res.items.at(0)
            if (resItem) {
              const newBike = {...resItem.fields, entry_id: resItem.sys.id}
              this.setBike(newBike)

              const plans = resItem.fields?.insurance?.fields?.plans
              plans?.map((el) => planIds.add(el.sys.id))
              this.setBikePlans(planIds)

              const colours = resItem.fields?.bikecolours
              colours?.map((el) => colourIds.add(el.sys.id))
              this.setBikeColours(colourIds)

            }
          })
          .catch((exception) => console.log(exception))
    }
  }

  fetchBikes() {
    const bikes = getBikes()
    const bikeArray: Array<BikeWithEntry> = []
    bikes.then((res) => {
           res.items.map((entries) => {
             const newBike = {...entries.fields, entry_id: entries.sys.id}
             bikeArray.push(newBike)
           })
           this.setBikes(bikeArray)
         })
         .catch((exception) => console.log(exception))
  }

  fetchPlans() {
    const plans = getPlans()
    const planArray: Array<Plan> = []
    plans.then((res) => {
      res.items.map((plans) => {
        planArray.push(plans.fields)
      })
      this.setPlans(planArray)
    })
  }

  fetchAdditionalItems() {
    const adds = getAdditional()
    const addsArray: Array<Additional> = []
    adds.then((res) => {
      res.items.map((ads) => {
        addsArray.push(ads.fields)
      })
      this.setAdditionalItems(addsArray)
    })
  }

}

export default BikeStore
