import React, { useContext, useEffect } from 'react'
import './App.css'
import { Route, Routes } from 'react-router-dom'
import MainLayout from "./components/MainLayout"
import BikeWrapper from "./pages/BikeWrapper"
import BikesListWrapper from "./pages/BikesListWrapper"
import CartWrapper from "./pages/CartWrapper"
import InsuranceWrapper from "./pages/InsuranceWrapper"
import OrderWrapper from "./pages/OrderWrapper"
import PaymentWrapper from "./pages/PaymentWrapper"
import { ApplicationContext } from "./index"
import { observer } from "mobx-react"


function App() {
  const appContext = useContext(ApplicationContext)

  useEffect(() => {
    appContext.fetchBikes()
    appContext.fetchPlans()
    appContext.fetchAdditionalItems()

  }, [])

  return (
    <Routes>
      <Route path="/" element={<MainLayout/>}>
        <Route index element={<BikesListWrapper/>}/>
        <Route path="/products/:entryId" element={<BikeWrapper/>}/>
        <Route path="/cart/:entryId" element={<CartWrapper/>}/>
        <Route path="/add-insurance/:entryId" element={<InsuranceWrapper/>}/>
        <Route path="/payment/:entryId" element={<PaymentWrapper/>}/>
        <Route path="/order-confirmation/:entryId" element={<OrderWrapper/>}/>
      </Route>
    </Routes>
  )
}

export default observer(App)
