import React, { createContext, Suspense } from 'react'
import ReactDOM from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import App from './App'
import LoadingSplash from './components/LoadingSplash'
import './index.css'
import reportWebVitals from './reportWebVitals'
import BikeStore from "./store/store"

export const ApplicationContext = createContext<BikeStore>(new BikeStore())

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement,
)

root.render(
    <BrowserRouter>
            {/* eslint-disable-next-line react/jsx-no-constructed-context-values */}
            <ApplicationContext.Provider value={new BikeStore()}>
                    <Suspense
                        fallback={(
                            <div style={{
                                position: 'absolute',
                                top: '40%',
                                left: '50%',
                            }}
                            >
                                <LoadingSplash />
                            </div>
                        )}
                    >
                            <React.StrictMode>
                                <App />
                            </React.StrictMode>

                    </Suspense>
            </ApplicationContext.Provider>
    </BrowserRouter>,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
